import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function MixtapePage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="mixtape" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="f8e48caf-038f-4062-b6b5-e963e15a7183" data-fixr-theme="light"></script>`}
      imageSrc="/logo-mixtape.png"
      backgroundSrc="/mixtape-hero.jpg"
      imageMaxWidth="562px"
      title="MIXTAPE PROJECT"
      description={`Leeds’ good vibes student basement boogie pioneers music with no boundaries, delivering a wild playlist of ear pleasers, new release gems, old school bangers & dancefloor fillers. The original feel good shindig, that welcomes movers & groovers with big smiles, open arms and can’t be categorised tunes. 13 years of throwing the cities friendliest weekly student parties!`}
    />
  );
}
